/* Center content */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
}

/* Button styling */
button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

/* Recording animation */
.recording-animation {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 20px auto;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.6);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

/* Styling for the audio player */
audio {
  width: 100%;
  max-width: 400px;
  margin-top: 15px;
  outline: none;
  border-radius: 5px;
}
